import * as React from "react"

import "./footer.scss"
import { Link } from "gatsby"

const Footer = () => (
  <footer>
    <div>
      <ul>
        <li><Link to="/sonstiges/impressum">Impressum & Datenschutz</Link></li>
      </ul>
    </div>
  </footer>
)

export default Footer;