import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "./header.scss"

const Header = ({ siteTitle }) => (
  <header>
    <div className='header-content'>
      <div className="main-navigation">
        <ul>
          <li><Link to="/">Aktuelles</Link></li>
          <li><Link to="/geschichte/">Geschichte</Link></li>
          <li><Link to="/galerie/">Galerie</Link></li>
        </ul>
      </div>
      <div className="header-logo">
        <div className="header-logo-content"><Link to="/">Weihnachtskrippe Dürrenwaldstetten</Link></div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
